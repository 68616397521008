import { useRouter } from 'next/navigation';
import { recordEvent } from '@qb/frontend/analytics/ga4';
import { EcommerceItem } from '@qb/frontend/analytics/ga4Types';
import { getGA4EcommerceCartItem } from '@qb/frontend/analytics/utils';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { LoginView } from '@/src/components/Auth/LoginView/LoginView';
import { useGetCurrentUserInfoRequest } from '@/src/components/Auth/hooks/useGetCurrentUserInfoRequest';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { useUpsertCartPartRequest } from './useUpsertCartPartRequest';
const headerTextsType = 'fromPartPage';
type UseBuyNowRequestParams = {
  partID: number;
  ga4data: {
    partNumber: string;
    price: number;
    qty: number;
    onHandQty: number;
    manufacturerName: string;
    partCategoryName: string;
    itemListName?: EcommerceItem['item_list_name'];
  };
};
export const useBuyNowRequest = ({
  partID,
  ga4data
}: UseBuyNowRequestParams) => {
  const router = useRouter();
  const currentUserInfoRequest = useGetCurrentUserInfoRequest();
  const isUserAuthenticated = Boolean(currentUserInfoRequest.data?.user?.id);
  const isMobile = useIsMobile();
  const {
    setActiveDrawer
  } = useRightDrawerContext();
  return useUpsertCartPartRequest(partID, async () => {
    const cartItem = getGA4EcommerceCartItem({
      cartPart: {
        part: {
          id: partID,
          partNumber: ga4data.partNumber,
          partCategory: {
            name: ga4data.partCategoryName
          },
          manufacturer: {
            name: ga4data.manufacturerName
          }
        },
        price: ga4data.price,
        qty: ga4data.qty
      },
      itemListNameFromParent: ga4data.itemListName
    });
    await recordEvent('buy_now', {
      attributes: {
        item_id: cartItem.item_id,
        item_name: cartItem.item_name
      },
      ecommerce: {
        items: [cartItem]
      }
    });
    if (isUserAuthenticated) {
      router.push('/cart');
      return;
    }
    if (isMobile) {
      router.push(`/login?headerTextsType=${headerTextsType}`);
    } else {
      setActiveDrawer(<LoginView viewType="drawer" headerTextsType={headerTextsType} />);
    }
  });
};